import React from 'react'
import './Loader.css'
export default function Loader() {
  return (
    <div className='loader-section' >
      <div className='Loader-bg'>
        <span className='loader'></span>

      </div>

    </div>
  )
}
